@use '../abstracts/variables' as v;
@use '../abstracts/breakpoints' as bp;
@use '../abstracts/mixins' as mx;
@use '../abstracts/placeholders';

%-btn-post-nav {
  width: 50%;
  position: relative;
  border-color: var(--btn-border-color);
}

@mixin -dot($pl: 0.25rem, $pr: 0.25rem) {
  content: '\2022';
  padding-left: $pl;
  padding-right: $pr;
}

header {
  .post-desc {
    @extend %heading;

    font-size: 1.125rem;
    line-height: 1.6;
  }

  .post-meta {
    span + span::before {
      @include -dot;
    }

    em,
    time {
      @extend %text-highlight;
    }

    em {
      a {
        color: inherit;
      }
    }
  }

  h1 + .post-meta {
    margin-top: 1.5rem;
  }
}

.post-tail-wrapper {
  @extend %text-sm;

  margin-top: 6rem;
  border-bottom: 1px double var(--main-border-color);

  .license-wrapper {
    line-height: 1.2rem;

    > a {
      @extend %text-highlight;

      &:hover {
        @extend %link-hover;
      }
    }

    span:last-child {
      @extend %text-sm;
    }
  } /* .license-wrapper */

  .post-meta a:not(:hover) {
    @extend %link-underline;
  }

  .share-wrapper {
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    %icon-size {
      font-size: 1.125rem;
    }

    .share-icons {
      display: flex;

      i {
        color: var(--btn-share-color);

        @extend %icon-size;
      }

      > * {
        @extend %icon-size;

        margin-left: 0.5rem;

        &:hover {
          i {
            @extend %btn-share-hover;
          }
        }
      }

      button {
        padding: 0;
        border: none;
        line-height: inherit;

        @extend %cursor-pointer;
      }
    } /* .share-icons */
  } /* .share-wrapper */
}

.post-tail-bottom {
  @include bp.lte(bp.get(sm)) {
    flex-wrap: wrap-reverse !important;

    > div:first-child {
      width: 100%;
      margin-top: 1rem;
    }
  }
}

.share-mastodon {
  /* See: https://github.com/justinribeiro/share-to-mastodon#properties */
  --wc-stm-font-family: v.$font-family-base;
  --wc-stm-dialog-background-color: var(--card-bg);
  --wc-stm-form-button-border: 1px solid var(--btn-border-color);
  --wc-stm-form-submit-background-color: var(--sidebar-btn-bg);
  --wc-stm-form-cancel-background-color: var(--sidebar-btn-bg);
  --wc-stm-form-button-background-color-hover: #007bff;
  --wc-stm-form-button-color-hover: white;

  font-size: 1rem;
}

.post-tags {
  line-height: 2rem;

  .post-tag {
    &:hover {
      @extend %link-hover;
      @extend %tag-hover;
      @extend %no-bottom-border;
    }
  }
}

.post-navigation {
  @include bp.lt(bp.get(lg)) {
    @include mx.pl-pr(0);
    @include mx.ml-mr(-0.5rem);
  }

  .btn {
    @extend %-btn-post-nav;

    &:not(:hover) {
      color: var(--link-color);
    }

    &:hover {
      &:not(.disabled)::before {
        color: whitesmoke;
      }
    }

    &.disabled {
      @extend %-btn-post-nav;

      pointer-events: auto;
      cursor: not-allowed;
      background: none;
      color: gray;
    }

    &.btn-outline-primary.disabled:focus {
      box-shadow: none;
    }

    &::before {
      color: var(--text-muted-color);
      font-size: 0.65rem;
      text-transform: uppercase;
      content: attr(aria-label);
    }

    &:first-child {
      border-radius: v.$radius-lg 0 0 v.$radius-lg;
      left: 0.5px;
    }

    &:last-child {
      border-radius: 0 v.$radius-lg v.$radius-lg 0;
      right: 0.5px;
    }
  }

  p {
    font-size: 1.1rem;
    line-height: 1.5rem;
    margin-top: 0.3rem;
    white-space: normal;
  }
} /* .post-navigation */

@media (hover: hover) {
  .post-navigation {
    .btn,
    .btn::before {
      transition: all 0.35s ease-in-out;
    }
  }
}

@-webkit-keyframes fade-up {
  from {
    opacity: 0;
    margin-top: 4rem;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-up {
  from {
    opacity: 0;
    margin-top: 4rem;
  }

  to {
    opacity: 1;
  }
}

/* TOC panel */

%top-cover {
  content: '';
  display: block;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 100%;
  height: 3rem;
  background: linear-gradient(var(--main-bg) 50%, transparent);
}

#toc-wrapper {
  top: 0;
  transition: top 0.2s ease-in-out;
  overflow-y: auto;
  max-height: 100vh;
  scrollbar-width: none;
  margin-top: 2rem;

  &:not(.invisible) {
    -webkit-animation: fade-up 0.8s;
    animation: fade-up 0.8s;
  }

  ul {
    list-style: none;
    font-size: 0.85rem;
    line-height: 1.25;
    padding-left: 0;

    li {
      a {
        padding: 0.4rem 0 0.4rem 1.25rem;
      }
    }

    /* Overwrite TOC plugin style */

    .toc-link {
      display: block;

      @extend %text-ellipsis;

      &:hover {
        color: var(--toc-highlight);
        text-decoration: none;
      }

      &::before {
        display: none;
      }
    }

    .is-active-link {
      color: var(--toc-highlight) !important;
      font-weight: 600;

      &::before {
        display: inline-block;
        width: 1px;
        height: 1.25rem;
        background-color: var(--toc-highlight) !important;
      }
    }

    ul {
      padding-left: 0.75rem;
    }
  }

  @at-root .toc-border-cover {
    @extend %top-cover;

    margin-bottom: -4rem;
  }

  &::before {
    @extend %top-cover;
  }

  &::after {
    content: '';
    position: fixed;
    bottom: 0;
    width: 15%;
    height: 2.25rem;
    margin-left: -1px;
    background: linear-gradient(transparent, var(--main-bg) 70%);
  }

  > * {
    @extend %panel-border;
  }
}

/* --- TOC button, bar and popup in mobile/tablet --- */

#toc-bar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  margin: 0 -1rem;
  height: v.$topbar-height;
  background: var(--main-bg);
  border-bottom: 1px solid var(--main-border-color);
  transition: all 0.2s ease-in-out;

  @extend %btn-color;

  @include bp.xl {
    display: none !important;
  }

  .label {
    @extend %heading;

    margin-left: 0.375rem;
    padding: 0 0.75rem;
    color: inherit;
  }

  &.invisible {
    top: -#{v.$topbar-height};
    transition: none;
  }
}

#toc-solo-trigger {
  color: var(--text-muted-color);
  border-color: var(--btn-border-color);
  border-radius: v.$radius-lg;

  @include bp.xl {
    display: none !important;
  }

  .label {
    font-size: 1rem;
    font-family: v.$font-family-heading;
  }

  &:hover {
    box-shadow: none;
    background: none;
  }
}

@mixin slide-in {
  from {
    opacity: 0.7;
    transform: translateY(-#{v.$topbar-height});
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@mixin slide-out {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(-#{v.$topbar-height});
    opacity: 0;
  }
}

@-webkit-keyframes slide-in {
  @include slide-in;
}

@keyframes slide-in {
  @include slide-in;
}

@-webkit-keyframes slide-out {
  @include slide-out;
}

@keyframes slide-out {
  @include slide-out;
}

/* --- Related Posts --- */

#related-posts {
  > h3 {
    @include mx.label(1.1rem, 600);
  }

  time {
    @extend %normal-font-style;
    @extend %text-xs;

    color: var(--text-muted-color);
  }

  p {
    @extend %text-ellipsis;

    font-size: 0.9rem;
    margin-bottom: 0.5rem;
    white-space: break-spaces;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .card {
    h4 {
      @extend %text-clip;
    }
  }
}

/* stylelint-disable-next-line selector-id-pattern */
#disqus_thread {
  min-height: 8.5rem;
}

.utterances {
  max-width: 100%;
  min-height: 269px;
}

%btn-share-hover {
  color: var(--btn-share-hover-color) !important;
}

.share-label {
  @include mx.label(inherit, 400, inherit);

  &::after {
    content: ':';
  }
}

.content > p > img {
  @include bp.lte(bp.get(md)) {
    max-width: calc(100% + 1rem);
  }
}

h2,
h3,
h4 {
  @include bp.xl {
    scroll-margin-top: 2rem;
  }
}
