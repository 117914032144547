@use '../abstracts/variables' as v;
@use '../abstracts/breakpoints' as bp;
@use '../abstracts/mixins' as mx;
@use '../abstracts/placeholders';
@use '../themes/light';
@use '../themes/dark';

:root {
  font-size: 16px;
}

html {
  @media (prefers-color-scheme: light) {
    &:not([data-mode]),
    &[data-mode='light'] {
      @include light.styles;
    }

    &[data-mode='dark'] {
      @include dark.styles;
    }
  }

  @media (prefers-color-scheme: dark) {
    &:not([data-mode]),
    &[data-mode='dark'] {
      @include dark.styles;
    }

    &[data-mode='light'] {
      @include light.styles;
    }
  }

  @include bp.lg {
    overflow-y: scroll;
  }
}

body {
  background: var(--main-bg);
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
  color: var(--text-color);
  -webkit-font-smoothing: antialiased;
  font-family: v.$font-family-base;
}

h1.dynamic-title {
  @include bp.lt(bp.get(lg)) {
    display: none;

    ~ .content {
      margin-top: 2.5rem;
    }
  }
}

main {
  &.col-12 {
    @include bp.xxxl {
      padding-right: 4.5rem !important;
    }
  }
}

.preview-img {
  aspect-ratio: 40 / 21;
  width: 100%;
  height: 100%;
  overflow: hidden;

  @extend %rounded;

  &:not(.no-bg) {
    background: var(--img-bg);
  }

  img {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;

    @extend %rounded;

    @at-root #post-list & {
      width: 100%;
    }
  }
}

.post-preview {
  @extend %rounded;

  border: 0;
  background: var(--card-bg);
  box-shadow: var(--card-shadow);

  &::before {
    @extend %rounded;

    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: var(--card-hovor-bg);
    opacity: 0;
    transition: opacity 0.35s ease-in-out;
  }

  &:hover {
    &::before {
      opacity: 0.3;
    }
  }
}

.post-meta {
  @extend %text-sm;

  a {
    &:not([class]):hover {
      @extend %link-hover;
    }
  }

  em {
    @extend %normal-font-style;
  }
}

.content {
  font-size: 1.08rem;
  margin-top: 2rem;
  overflow-wrap: break-word;

  @include bp.xl {
    font-size: 1.03rem;
  }

  a {
    &.popup {
      @extend %no-cursor;
      @extend %img-caption;
      @include mx.mt-mb(0.5rem);

      cursor: zoom-in;
    }

    &:not(.img-link) {
      @extend %link-underline;

      &:hover {
        @extend %link-hover;
      }
    }
  }

  ol,
  ul {
    &:not([class]),
    &.task-list {
      -webkit-padding-start: 1.75rem;
      padding-inline-start: 1.75rem;

      li {
        margin: 0.25rem 0;
        padding-left: 0.25rem;
      }

      ol,
      ul {
        -webkit-padding-start: 1.25rem;
        padding-inline-start: 1.25rem;
        margin: 0.5rem 0;
      }
    }
  }

  ul.task-list {
    -webkit-padding-start: 1.25rem;
    padding-inline-start: 1.25rem;

    li {
      list-style-type: none;
      padding-left: 0;

      /* checkbox icon */
      > i {
        width: 2rem;
        margin-left: -1.25rem;
        color: var(--checkbox-color);

        &.checked {
          color: var(--checkbox-checked-color);
        }
      }

      ul {
        -webkit-padding-start: 1.75rem;
        padding-inline-start: 1.75rem;
      }
    }

    input[type='checkbox'] {
      margin: 0 0.5rem 0.2rem -1.3rem;
      vertical-align: middle;
    }
  } /* ul */

  dl > dd {
    margin-left: 1rem;
  }

  ::marker {
    color: var(--text-muted-color);
  }

  .table-wrapper > table {
    @include bp.lg {
      min-width: 70%;
    }
  }
} /* .content */

.tag:hover {
  @extend %tag-hover;
}

.post-tag {
  display: inline-block;
  min-width: 2rem;
  text-align: center;
  border-radius: 0.5rem;
  border: 1px solid var(--btn-border-color);
  padding: 0 0.4rem;
  color: var(--text-muted-color);
  line-height: 1.3rem;

  &:not(:last-child) {
    margin-right: 0.2rem;
  }
}

.rounded-10 {
  border-radius: 10px !important;
}

.img-link {
  color: transparent;
  display: inline-flex;
}

.shimmer {
  overflow: hidden;
  position: relative;
  background: var(--img-bg);

  &::before {
    content: '';
    position: absolute;
    background: var(--shimmer-bg);
    height: 100%;
    width: 100%;
    -webkit-animation: shimmer 1.3s infinite;
    animation: shimmer 1.3s infinite;
  }

  @-webkit-keyframes shimmer {
    0% {
      transform: translateX(-100%);
    }

    100% {
      transform: translateX(100%);
    }
  }

  @keyframes shimmer {
    0% {
      transform: translateX(-100%);
    }

    100% {
      transform: translateX(100%);
    }
  }
}

.embed-video {
  width: 100%;
  height: 100%;
  margin-bottom: 1rem;
  aspect-ratio: 16 / 9;

  @extend %rounded;

  &.twitch {
    aspect-ratio: 310 / 189;
  }

  &.file {
    display: block;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    margin-bottom: 0;
  }

  @extend %img-caption;
}

.embed-audio {
  width: 100%;
  display: block;

  @extend %img-caption;
}

/* --- Effects classes --- */

.flex-grow-1 {
  flex-grow: 1 !important;
}

.btn-box-shadow {
  box-shadow: var(--card-shadow);
}

/* overwrite bootstrap muted */
.text-muted {
  color: var(--text-muted-color) !important;
}

/* Overwrite bootstrap tooltip */
.tooltip-inner {
  font-size: 0.7rem;
  max-width: 220px;
  text-align: left;
}

/* Overwrite bootstrap outline button */
.btn.btn-outline-primary {
  &:not(.disabled):hover {
    border-color: #007bff !important;
  }
}

.disabled {
  color: rgb(206, 196, 196);
  pointer-events: auto;
  cursor: not-allowed;
}

.hide-border-bottom {
  border-bottom: none !important;
}

.input-focus {
  box-shadow: none;
  border-color: var(--input-focus-border-color) !important;
  background: center !important;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

.left {
  float: left;
  margin: 0.75rem 1rem 1rem 0;
}

.right {
  float: right;
  margin: 0.75rem 0 1rem 1rem;
}

/* --- Overriding --- */

/* mermaid */
.mermaid {
  text-align: center;
}

/* MathJax */
mjx-container {
  overflow-y: hidden;
  min-width: auto !important;
}

@media (hover: hover) {
  #sidebar ul > li:last-child::after {
    transition: top 0.5s ease;
  }

  .nav-link {
    transition: background-color 0.3s ease-in-out;
  }

  .post-preview {
    transition: background-color 0.35s ease-in-out;
  }
}

#mask {
  inset: 0 0 0 0;
}

#main-wrapper {
  position: relative;

  @include mx.pl-pr(0);

  @include bp.lt(bp.get(lg)) {
    @include mx.slide;
  }

  @include bp.lg {
    margin-left: v.$sidebar-width;
  }

  @include bp.xxxl {
    margin-left: v.$sidebar-width-large;
  }

  > .container {
    min-height: 100vh;

    @include bp.lte(bp.get(md)) {
      @include mx.max-w-100;
      @include mx.pl-pr(0);
    }

    @include bp.lt(bp.get(lg)) {
      max-width: 100%;
    }

    /* Pad horizontal */
    @include bp.between(992px, calc(#{bp.get(xl)} - 1px)) {
      .col-lg-11 {
        flex: 0 0 96%;
        max-width: 96%;
      }
    }

    @include bp.lt(bp.get(xl)) {
      > .row {
        justify-content: center !important;
      }
    }

    @include bp.xxxl {
      max-width: v.$main-content-max-width;

      @include mx.pl-pr(1.75rem, true);
    }
  }
}

/* --- basic wrappers --- */

#topbar-wrapper.row,
#main-wrapper > .container > .row,
#search-result-wrapper > .row {
  @include mx.ml-mr(0);
}

#tail-wrapper {
  @include bp.xxxl {
    padding-right: 4.5rem !important;
  }

  > :not(script) {
    margin-top: 3rem;
  }
}
