@use 'variables' as v;
@use 'mixins' as mx;

%heading {
  color: var(--heading-color);
  font-weight: 400;
  font-family: v.$font-family-heading;
  scroll-margin-top: 3.5rem;
}

%anchor {
  .anchor {
    font-size: 80%;
  }

  @media (hover: hover) {
    .anchor {
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.25s ease-in, visibility 0s ease-in 0.25s;
    }

    &:hover {
      .anchor {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.25s ease-in, visibility 0s ease-in 0s;
      }
    }
  }
}

%tag-hover {
  background: var(--tag-hover);
  transition: background 0.35s ease-in-out;
}

%table-cell {
  padding: 0.4rem 1rem;
  font-size: 95%;
  white-space: nowrap;
}

%link-hover {
  color: #d2603a !important;
  border-bottom: 1px solid #d2603a;
  text-decoration: none;
}

%link-color {
  color: var(--link-color);
}

%link-underline {
  border-bottom: 1px solid var(--link-underline-color);
}

%clickable-transition {
  transition: all 0.3s ease-in-out;
}

%no-cursor {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

%no-bottom-border {
  border-bottom: none;
}

%cursor-pointer {
  cursor: pointer;
}

%normal-font-style {
  font-style: normal;
}

%rounded {
  border-radius: v.$radius-lg;
}

%img-caption {
  + em {
    display: block;
    text-align: center;
    font-style: normal;
    font-size: 80%;
    padding: 0;
    color: #6d6c6c;
  }
}

%sidebar-links {
  color: var(--sidebar-muted-color);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

%text-clip {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

%text-ellipsis {
  @include mx.text-ellipsis;
}

%text-highlight {
  color: var(--text-muted-highlight-color);
  font-weight: 600;
}

%text-sm {
  font-size: 0.85rem;
}

%text-xs {
  font-size: 0.8rem;
}

%sup-fn-target {
  &:target {
    background-color: var(--footnote-target-bg);
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    transition: background-color 1.75s ease-in-out;
  }
}

%btn-color {
  button i {
    color: #999999;
  }
}

%code-snippet-bg {
  background-color: var(--highlight-bg-color);
}

%code-snippet-padding {
  padding-left: 1rem;
  padding-right: 1.5rem;
}

%max-w-100 {
  max-width: 100%;
}

%panel-border {
  border-left: 1px solid var(--main-border-color);
}
