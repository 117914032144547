@use '../abstracts/variables' as v;
@use '../abstracts/breakpoints' as bp;
@use '../abstracts/placeholders';

#post-list {
  margin-top: 2rem;

  @include bp.lg {
    margin-top: 2.5rem;
  }

  .card-wrapper {
    &:hover {
      text-decoration: none;
    }

    &:not(:last-child) {
      margin-bottom: 1.25rem;
    }
  }

  .card {
    border: 0;
    background: none;

    %img-radius {
      border-radius: v.$radius-lg v.$radius-lg 0 0;

      @include bp.md {
        border-radius: 0 v.$radius-lg v.$radius-lg 0;
      }
    }

    .preview-img {
      @extend %img-radius;

      img {
        @extend %img-radius;
      }
    }

    .card-body {
      height: 100%;
      padding: 1rem;

      @include bp.md {
        padding: 1.75rem 1.75rem 1.25rem;
      }

      .card-title {
        @extend %text-clip;

        color: var(--heading-color) !important;
        font-size: 1.25rem;
      }

      %muted {
        color: var(--text-muted-color) !important;
      }

      .card-text {
        @include bp.md {
          display: inherit !important;
        }

        &.content {
          @extend %muted;

          p {
            @extend %text-clip;

            line-height: 1.5;
            margin: 0;
          }
        }
      }

      .post-meta {
        @extend %muted;

        i {
          &:not(:first-child) {
            margin-left: 1.5rem;

            @include bp.md {
              margin-left: 1.75rem;
            }
          }
        }

        em {
          @extend %normal-font-style;

          color: inherit;
        }

        > div:first-child {
          display: block;

          @extend %text-ellipsis;
        }
      }
    }
  }
} /* #post-list */

.pagination {
  color: var(--text-color);
  font-family: Lato, sans-serif;
  justify-content: space-evenly;

  @include bp.lg {
    font-size: 0.85rem;
    justify-content: center;
  }

  a:hover {
    text-decoration: none;
  }

  .page-item {
    @include bp.lt(bp.get(lg)) {
      &:not(:first-child):not(:last-child) {
        display: none;
      }
    }

    @include bp.lg {
      &:not(:last-child) {
        margin-right: 0.7rem;
      }
    }

    .page-link {
      color: var(--btn-patinator-text-color);
      padding: 0 0.6rem;
      display: -webkit-box;
      -webkit-box-pack: center;
      -webkit-box-align: center;
      border-radius: 0.5rem;
      border: 0;
      background-color: inherit;
    }

    &.active {
      .page-link {
        background-color: var(--btn-paginator-hover-color);
      }
    }

    &:not(.active) {
      .page-link {
        &:hover {
          box-shadow: inset var(--btn-border-color) 0 0 0 1px;
        }
      }
    }

    &.disabled {
      cursor: not-allowed;

      .page-link {
        color: rgba(108, 117, 125, 0.57);
      }
    }
  } /* .page-item */

  .page-index {
    @include bp.lg {
      display: none;
    }
  }
}
