@use '../abstracts/variables' as v;
@use '../abstracts/breakpoints' as bp;
@use '../abstracts/mixins' as mx;
@use '../abstracts/placeholders';

@for $i from 1 through 5 {
  h#{$i} {
    @extend %heading;

    @if $i > 1 {
      @extend %anchor;
    }

    @if $i < 5 {
      $size-factor: 0.25rem;

      @if $i > 1 {
        $size-factor: 0.18rem;

        main & {
          @if $i == 2 {
            margin: 2.5rem 0 1.25rem;
          } @else {
            margin: 2rem 0 1rem;
          }
        }
      }

      & {
        font-size: 1rem + (5 - $i) * $size-factor;
      }
    } @else {
      font-size: 1.05rem;
    }
  }
}

a {
  @extend %link-color;

  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
  transition: all 0.35s ease-in-out;

  .blur & {
    $blur: 20px;

    -webkit-filter: blur($blur);
    filter: blur($blur);
  }
}

blockquote {
  border-left: 0.125rem solid var(--blockquote-border-color);
  padding-left: 1rem;
  color: var(--blockquote-text-color);
  margin-top: 0.5rem;

  > p:last-child {
    margin-bottom: 0;
  }

  &[class^='prompt-'] {
    border-left: 0;
    position: relative;
    padding: 1rem 1rem 1rem 3rem;
    color: var(--prompt-text-color);

    @extend %rounded;

    &::before {
      text-align: center;
      width: 3rem;
      position: absolute;
      left: 0.25rem;
      margin-top: 0.4rem;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
    }
  }

  @include mx.prompt('tip', '\f0eb', $fa-style: 'regular');
  @include mx.prompt('info', '\f06a', $rotate: 180);
  @include mx.prompt('warning', '\f06a');
  @include mx.prompt('danger', '\f071');
}

kbd {
  font-family: Lato, sans-serif;
  display: inline-block;
  vertical-align: middle;
  line-height: 1.3rem;
  min-width: 1.75rem;
  text-align: center;
  margin: 0 0.3rem;
  padding-top: 0.1rem;
  color: var(--kbd-text-color);
  background-color: var(--kbd-bg-color);
  border-radius: v.$radius-sm;
  border: solid 1px var(--kbd-wrap-color);
  box-shadow: inset 0 -2px 0 var(--kbd-wrap-color);
}

hr {
  border-color: var(--main-border-color);
  opacity: 1;
}

footer {
  background-color: var(--main-bg);
  height: v.$footer-height;
  border-top: 1px solid var(--main-border-color);

  @extend %text-xs;

  a {
    @extend %text-highlight;

    &:hover {
      @extend %link-hover;
    }
  }

  em {
    @extend %text-highlight;
  }

  p {
    text-align: center;
    margin-bottom: 0;
  }
}

/* fontawesome icons */
i {
  &.far,
  &.fas {
    @extend %no-cursor;
  }
}

sup {
  @extend %sup-fn-target;
}

main {
  line-height: 1.75;

  h1 {
    margin-top: 2rem;

    @include bp.lg {
      margin-top: 3rem;
    }
  }

  p {
    > a.popup {
      &:not(.normal):not(.left):not(.right) {
        @include mx.align-center;
      }
    }
  }

  .categories,
  #tags,
  #archives {
    a:not(:hover) {
      @extend %no-bottom-border;
    }
  }

  @include bp.lte(bp.get(sm)) {
    .content {
      > blockquote[class^='prompt-'] {
        @include mx.ml-mr(-1rem);

        border-radius: 0;
        max-width: none;
      }
    }
  }
}

.footnotes > ol {
  padding-left: 2rem;
  margin-top: 0.5rem;

  > li {
    &:not(:last-child) {
      margin-bottom: 0.3rem;
    }

    @extend %sup-fn-target;

    > p {
      margin-left: 0.25em;

      @include mx.mt-mb(0);
    }
  }
}

.footnote {
  @at-root a#{&} {
    @include mx.ml-mr(1px);
    @include mx.pl-pr(2px);

    border-bottom-style: none !important;
  }
}

.reversefootnote {
  @at-root a#{&} {
    font-size: 0.6rem;
    line-height: 1;
    position: relative;
    bottom: 0.25em;
    margin-left: 0.25em;
    border-bottom-style: none !important;
  }
}

/* --- Begin of Markdown table style --- */

/* it will be created by Liquid */
.table-wrapper {
  overflow-x: auto;
  margin-bottom: 1.5rem;

  > table {
    min-width: 100%;
    overflow-x: auto;
    border-spacing: 0;

    thead {
      border-bottom: solid 2px rgba(210, 215, 217, 0.75);

      th {
        @extend %table-cell;
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid var(--tb-border-color);

        &:nth-child(2n) {
          background-color: var(--tb-even-bg);
        }

        &:nth-child(2n + 1) {
          background-color: var(--tb-odd-bg);
        }

        td {
          @extend %table-cell;
        }
      }
    } /* tbody */
  } /* table */
}
