@use '../abstracts/variables' as v;
@use '../abstracts/breakpoints' as bp;

#back-to-top {
  visibility: hidden;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  position: fixed;
  right: 1rem;
  bottom: calc(v.$footer-height-large - v.$back2top-size / 2);
  background: var(--button-bg);
  color: var(--btn-backtotop-color);
  padding: 0;
  width: v.$back2top-size;
  height: v.$back2top-size;
  border-radius: 50%;
  border: 1px solid var(--btn-backtotop-border-color);
  transition: opacity 0.5s ease-in-out, transform 0.2s ease-out;

  @include bp.lg {
    right: 5%;
    bottom: calc(v.$footer-height - v.$back2top-size / 2);
  }

  @include bp.xxl {
    right: calc((100vw - v.$sidebar-width - 1140px) / 2 + 3rem);
  }

  @include bp.xxxl {
    right: calc(
      (100vw - v.$sidebar-width-large - v.$main-content-max-width) / 2 + 2rem
    );
  }

  &:hover {
    transform: translate3d(0, -5px, 0);
    -webkit-transform: translate3d(0, -5px, 0);
  }

  i {
    line-height: v.$back2top-size;
    position: relative;
    bottom: 2px;
  }

  &.show {
    opacity: 1;
    visibility: visible;
  }
}
