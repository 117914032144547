@use '../abstracts/breakpoints' as bp;
@use '../abstracts/variables' as v;
@use '../abstracts/placeholders';

search {
  display: flex;
  width: 100%;
  border-radius: 1rem;
  border: 1px solid var(--search-border-color);
  background: var(--main-bg);
  padding: 0 0.5rem;

  i {
    z-index: 2;
    font-size: 0.9rem;
    color: var(--search-icon-color);
  }

  @include bp.lt(bp.get(lg)) {
    display: none;
  }

  @include bp.lg {
    max-width: v.$search-max-width;
  }

  @include bp.xl {
    margin-right: 4rem;
  }

  @include bp.xxxl {
    margin-right: calc(
      v.$main-content-max-width / 4 - v.$search-max-width - 0.75rem
    );
  }
}

#search-result-wrapper {
  display: none;
  height: 100%;
  width: 100%;
  overflow: auto;

  .content {
    margin-top: 2rem;
  }

  @include bp.lt(bp.get(lg)) {
    width: 100%;

    .content {
      letter-spacing: 0;
    }
  }

  @include bp.lg {
    max-width: v.$main-content-max-width;
    justify-content: start !important;
  }
}

#search-results {
  padding-bottom: 3rem;

  @include bp.between(bp.get(lg), calc(#{bp.get(xl)} - 1px)) {
    > div {
      max-width: 700px;
    }
  }

  a {
    font-size: 1.4rem;
    line-height: 1.5rem;

    &:hover {
      @extend %link-hover;
    }

    @extend %link-color;
    @extend %no-bottom-border;
    @extend %heading;
  }

  > article {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    @include bp.xl {
      width: 45%;

      &:nth-child(odd) {
        margin-right: 1.5rem;
      }

      &:nth-child(even) {
        margin-left: 1.5rem;
      }

      &:last-child:nth-child(odd) {
        position: relative;
        right: 24.3%;
      }
    }

    h2 {
      line-height: 2.5rem;
    }

    /* icons */
    i {
      color: #818182;
      margin-right: 0.15rem;
      font-size: 80%;
    }

    > p {
      @extend %text-ellipsis;

      white-space: break-spaces;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
}

/* 'Cancel' link */
#search-cancel {
  color: var(--link-color);
  display: none;
  white-space: nowrap;

  @extend %cursor-pointer;
}

#search-input {
  background: center;
  border: 0;
  border-radius: 0;
  padding: 0.18rem 0.3rem;
  color: var(--text-color);
  height: auto;

  &:focus {
    box-shadow: none;
  }

  @include bp.xl {
    transition: all 0.3s ease-in-out;
  }
}

#search-hints {
  padding: 0 1rem;

  @include bp.lg {
    display: none;
  }

  h4 {
    margin-bottom: 1.5rem;
  }

  .post-tag {
    display: inline-block;
    line-height: 1rem;
    font-size: 1rem;
    background: var(--search-tag-bg);
    border: none;
    padding: 0.5rem;
    margin: 0 1.25rem 1rem 0;

    &::before {
      content: '#';
      color: var(--text-muted-color);
      padding-right: 0.2rem;
    }

    @extend %link-color;
  }
}
