@mixin styles {
  color-scheme: dark;

  /* Framework color */
  --main-bg: rgb(27, 27, 30);
  --mask-bg: rgb(68, 69, 70);
  --main-border-color: rgb(44, 45, 45);

  /* Common color */
  --text-color: rgb(175, 176, 177);
  --text-muted-color: #868686;
  --text-muted-highlight-color: #aeaeae;
  --heading-color: #cccccc;
  --label-color: #a7a7a7;
  --blockquote-border-color: rgb(66, 66, 66);
  --blockquote-text-color: #868686;
  --link-color: rgb(138, 180, 248);
  --link-underline-color: rgb(82, 108, 150);
  --button-bg: #1e1e1e;
  --btn-border-color: #2e2f31;
  --btn-backtotop-color: var(--text-color);
  --btn-backtotop-border-color: #212122;
  --card-header-bg: #292929;
  --checkbox-color: rgb(118, 120, 121);
  --checkbox-checked-color: var(--link-color);
  --img-bg: radial-gradient(circle, rgb(22, 22, 24) 0%, rgb(32, 32, 32) 100%);
  --shimmer-bg: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(58, 55, 55, 0.4) 50%,
    rgba(255, 255, 255, 0) 100%
  );

  /* Sidebar */
  --site-title-color: #717070;
  --site-subtitle-color: #868686;
  --sidebar-bg: #1e1e1e;
  --sidebar-border-color: #292929;
  --sidebar-muted-color: #868686;
  --sidebar-active-color: rgb(255, 255, 255, 0.95);
  --sidebar-hover-bg: #262626;
  --sidebar-btn-bg: #232328;
  --sidebar-btn-color: #787878;
  --avatar-border-color: rgb(206, 206, 206, 0.9);

  /* Topbar */
  --topbar-bg: rgb(27, 27, 30, 0.64);
  --topbar-text-color: var(--text-color);
  --search-border-color: rgb(55, 55, 55);
  --search-icon-color: rgb(100, 102, 105);
  --input-focus-border-color: rgb(112, 114, 115);

  /* Home page */
  --post-list-text-color: rgb(175, 176, 177);
  --btn-patinator-text-color: var(--text-color);
  --btn-paginator-hover-color: #2e2e2e;

  /* Posts */
  --toc-highlight: rgb(116, 178, 243);
  --toc-popup-border-color: #373737;
  --tag-hover: rgb(43, 56, 62);
  --tb-odd-bg: #252526; /* odd rows of the posts' table */
  --tb-even-bg: rgb(31, 31, 34); /* even rows of the posts' table */
  --tb-border-color: var(--tb-odd-bg);
  --footnote-target-bg: rgb(63, 81, 181);
  --btn-share-color: #6c757d;
  --btn-share-hover-color: #bfc1ca;
  --card-bg: #1e1e1e;
  --card-hovor-bg: #464d51;
  --card-shadow: rgb(21, 21, 21, 0.72) 0 6px 18px 0,
    rgb(137, 135, 135, 0.24) 0 0 0 1px;
  --kbd-wrap-color: #6a6a6a;
  --kbd-text-color: #d3d3d3;
  --kbd-bg-color: #242424;
  --prompt-text-color: rgb(216, 212, 212, 0.75);
  --prompt-tip-bg: rgb(22, 60, 36, 0.64);
  --prompt-tip-icon-color: rgb(15, 164, 15, 0.81);
  --prompt-info-bg: rgb(7, 59, 104, 0.8);
  --prompt-info-icon-color: #0075d1;
  --prompt-warning-bg: rgb(90, 69, 3, 0.88);
  --prompt-warning-icon-color: rgb(255, 165, 0, 0.8);
  --prompt-danger-bg: rgb(86, 28, 8, 0.8);
  --prompt-danger-icon-color: #cd0202;

  /* Tags */
  --tag-border: rgb(59, 79, 88);
  --tag-shadow: rgb(32, 33, 33);
  --dash-color: rgb(63, 65, 68);
  --search-tag-bg: #292828;

  /* Categories */
  --categories-border: rgb(64, 66, 69, 0.5);
  --categories-hover-bg: rgb(73, 75, 76);
  --categories-icon-hover-color: white;

  /* Archive */
  --timeline-node-bg: rgb(150, 152, 156);
  --timeline-color: rgb(63, 65, 68);
  --timeline-year-dot-color: var(--timeline-color);

  /* Code highlight colors */
  --language-border-color: #2d2d2d;
  --highlight-bg-color: #151515;
  --highlighter-rouge-color: #c9def1;
  --highlight-lineno-color: #808080;
  --inline-code-bg: rgba(255, 255, 255, 0.05);
  --code-color: #b0b0b0;
  --code-header-text-color: #6a6a6a;
  --code-header-muted-color: #353535;
  --code-header-icon-color: #565656;
  --clipboard-checked-color: #2bcc2b;
  --filepath-text-color: #cacaca;

  .light {
    display: none;
  }

  /* Categories */
  .categories.card,
  .list-group-item {
    background-color: var(--card-bg);
  }

  .categories {
    .card-header {
      background-color: var(--card-header-bg);
    }

    .list-group-item {
      border-left: none;
      border-right: none;
      padding-left: 2rem;
      border-color: var(--categories-border);

      &:last-child {
        border-bottom-color: var(--card-bg);
      }
    }
  }

  #archives li:nth-child(odd) {
    background-image: linear-gradient(
      to left,
      rgb(26, 26, 30),
      rgb(39, 39, 45),
      rgb(39, 39, 45),
      rgb(39, 39, 45),
      rgb(26, 26, 30)
    );
  }

  /* stylelint-disable-next-line selector-id-pattern */
  #disqus_thread {
    color-scheme: none;
  }

  /* --- Syntax highlight theme from `rougify style base16.dark` --- */

  .highlight .gp {
    color: #87939d;
  }

  .highlight table td {
    padding: 5px;
  }

  .highlight table pre {
    margin: 0;
  }

  .highlight,
  .highlight .w {
    color: #d0d0d0;
    background-color: #151515;
  }

  .highlight .err {
    color: #151515;
    background-color: #ac4142;
  }

  .highlight .c,
  .highlight .ch,
  .highlight .cd,
  .highlight .cm,
  .highlight .cpf,
  .highlight .c1,
  .highlight .cs {
    color: #848484;
  }

  .highlight .cp {
    color: #f4bf75;
  }

  .highlight .nt {
    color: #f4bf75;
  }

  .highlight .o,
  .highlight .ow {
    color: #d0d0d0;
  }

  .highlight .p,
  .highlight .pi {
    color: #d0d0d0;
  }

  .highlight .gi {
    color: #90a959;
  }

  .highlight .gd {
    color: #f08a8b;
    background-color: #320000;
  }

  .highlight .gh {
    color: #6a9fb5;
    background-color: #151515;
    font-weight: bold;
  }

  .highlight .k,
  .highlight .kn,
  .highlight .kp,
  .highlight .kr,
  .highlight .kv {
    color: #aa759f;
  }

  .highlight .kc {
    color: #d28445;
  }

  .highlight .kt {
    color: #d28445;
  }

  .highlight .kd {
    color: #d28445;
  }

  .highlight .s,
  .highlight .sb,
  .highlight .sc,
  .highlight .dl,
  .highlight .sd,
  .highlight .s2,
  .highlight .sh,
  .highlight .sx,
  .highlight .s1 {
    color: #90a959;
  }

  .highlight .sa {
    color: #aa759f;
  }

  .highlight .sr {
    color: #75b5aa;
  }

  .highlight .si {
    color: #b76d45;
  }

  .highlight .se {
    color: #b76d45;
  }

  .highlight .nn {
    color: #f4bf75;
  }

  .highlight .nc {
    color: #f4bf75;
  }

  .highlight .no {
    color: #f4bf75;
  }

  .highlight .na {
    color: #6a9fb5;
  }

  .highlight .m,
  .highlight .mb,
  .highlight .mf,
  .highlight .mh,
  .highlight .mi,
  .highlight .il,
  .highlight .mo,
  .highlight .mx {
    color: #90a959;
  }

  .highlight .ss {
    color: #90a959;
  }
}
