@use '../abstracts/variables' as v;
@use '../abstracts/mixins' as mx;
@use '../abstracts/breakpoints' as bp;
@use '../abstracts/placeholders';

$btn-border-width: 3px;
$btn-mb: 0.5rem;
$sidebar-display: 'sidebar-display'; /* the attribute for sidebar display */

#sidebar {
  @include mx.pl-pr(0);

  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
  width: v.$sidebar-width;
  background: var(--sidebar-bg);
  border-right: 1px solid var(--sidebar-border-color);

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  @include bp.lt(bp.get(lg)) {
    @include mx.slide;

    transform: translateX(-#{v.$sidebar-width}); /* hide */
    -webkit-transform: translateX(-#{v.$sidebar-width});

    [#{$sidebar-display}] & {
      transform: translateX(0);
    }
  }

  @include bp.xxxl {
    width: v.$sidebar-width-large;
  }

  %sidebar-link-hover {
    &:hover {
      color: var(--sidebar-active-color);
    }
  }

  a {
    @extend %sidebar-links;
  }

  #avatar {
    display: block;
    width: 6.5rem;
    height: 6.5rem;
    overflow: hidden;
    box-shadow: var(--avatar-border-color) 0 0 0 2px;
    transform: translateZ(0); /* fixed the zoom in Safari */

    @include bp.sm {
      width: 7rem;
      height: 7rem;
    }

    img {
      transition: transform 0.5s;

      &:hover {
        transform: scale(1.2);
      }
    }
  }

  .profile-wrapper {
    @include mx.mt-mb(2.5rem);
    @extend %clickable-transition;

    padding-left: 2.5rem;
    padding-right: 1.25rem;
    width: 100%;

    @include bp.lg {
      margin-top: 3rem;
    }

    @include bp.xxxl {
      margin-top: 3.5rem;
      margin-bottom: 2.5rem;
      padding-left: 3.5rem;
    }
  }

  .site-title {
    @extend %clickable-transition;
    @extend %sidebar-link-hover;

    font-family: inherit;
    font-weight: 900;
    font-size: 1.75rem;
    line-height: 1.2;
    letter-spacing: 0.25px;
    margin-top: 1.25rem;
    margin-bottom: 0.5rem;
    width: fit-content;
    color: var(--site-title-color);
  }

  .site-subtitle {
    font-size: 95%;
    color: var(--site-subtitle-color);
    margin-top: 0.25rem;
    word-spacing: 1px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  ul {
    margin-bottom: 2rem;

    li.nav-item {
      opacity: 0.9;
      width: 100%;

      @include mx.pl-pr(1.5rem);

      @include bp.xxxl {
        @include mx.pl-pr(2.75rem);
      }

      a.nav-link {
        @include mx.pt-pb(0.6rem);

        display: flex;
        align-items: center;
        border-radius: 0.75rem;
        font-weight: 600;

        &:hover {
          background-color: var(--sidebar-hover-bg);
        }

        i {
          font-size: 95%;
          opacity: 0.8;
          margin-right: 1.5rem;
        }

        span {
          font-size: 90%;
          letter-spacing: 0.2px;
        }
      }

      &.active {
        .nav-link {
          color: var(--sidebar-active-color);
          background-color: var(--sidebar-hover-bg);

          span {
            opacity: 1;
          }
        }
      }

      &:not(:first-child) {
        margin-top: 0.25rem;
      }
    }
  }

  .sidebar-bottom {
    padding-left: 2rem;
    padding-right: 1rem;
    margin-bottom: 1.5rem;

    @include bp.xxxl {
      padding-left: 2.75rem;
      margin-bottom: 1.75rem;
    }

    $btn-size: 1.75rem;

    %button {
      width: $btn-size;
      height: $btn-size;
      margin-bottom: $btn-mb; // multi line gap
      border-radius: 50%;
      color: var(--sidebar-btn-color);
      background-color: var(--sidebar-btn-bg);
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      &:not(:focus-visible) {
        box-shadow: var(--sidebar-border-color) 0 0 0 1px;
      }

      &:hover {
        background-color: var(--sidebar-hover-bg);
      }
    }

    a {
      @extend %button;
      @extend %sidebar-link-hover;
      @extend %clickable-transition;

      &:not(:last-child) {
        margin-right: v.$sb-btn-gap;

        @include bp.xxxl {
          margin-right: v.$sb-btn-gap-lg;
        }
      }
    }

    i {
      line-height: $btn-size;
    }

    #mode-toggle {
      @extend %button;
      @extend %sidebar-links;
      @extend %sidebar-link-hover;
    }

    .icon-border {
      @extend %no-cursor;
      @include mx.ml-mr(calc((v.$sb-btn-gap - $btn-border-width) / 2));

      background-color: var(--sidebar-btn-color);
      content: '';
      width: $btn-border-width;
      height: $btn-border-width;
      border-radius: 50%;
      margin-bottom: $btn-mb;

      @include bp.xxxl {
        @include mx.ml-mr(calc((v.$sb-btn-gap-lg - $btn-border-width) / 2));
      }
    }
  } /* .sidebar-bottom */
} /* #sidebar */

[#{$sidebar-display}] {
  #main-wrapper {
    @include bp.lt(bp.get(lg)) {
      transform: translateX(v.$sidebar-width);
    }
  }
}
