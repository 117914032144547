@use '../abstracts/mixins' as *;

::-webkit-input-placeholder {
  @include placeholder;
}

::-moz-placeholder {
  @include placeholder;
}

:-ms-input-placeholder {
  @include placeholder;
}

::-ms-input-placeholder {
  @include placeholder;
}

::placeholder {
  @include placeholder;
}

:focus::-webkit-input-placeholder {
  @include placeholder-focus;
}

:focus::-moz-placeholder {
  @include placeholder-focus;
}

:focus:-ms-input-placeholder {
  @include placeholder-focus;
}

:focus::-ms-input-placeholder {
  @include placeholder-focus;
}

:focus::placeholder {
  @include placeholder-focus;
}
