@use 'sass:map';

$-breakpoints: (
  // 1 column
  sm: 576px,
  md: 768px,
  // 2 columns
  lg: 850px,
  // 3 columns
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1650px
);

@function get($breakpoint) {
  @return map.get($-breakpoints, $breakpoint);
}

/* Less than the given width */
@mixin lt($width) {
  @media all and (max-width: calc(#{$width} - 1px)) {
    @content;
  }
}

/* Less than or equal to the given width */
@mixin lte($width) {
  @media all and (max-width: $width) {
    @content;
  }
}

@mixin sm {
  @media all and (min-width: get(sm)) {
    @content;
  }
}

@mixin md {
  @media all and (min-width: get(md)) {
    @content;
  }
}

@mixin lg {
  @media all and (min-width: get(lg)) {
    @content;
  }
}

@mixin xl {
  @media all and (min-width: get(xl)) {
    @content;
  }
}

@mixin xxl {
  @media all and (min-width: get(xxl)) {
    @content;
  }
}

@mixin xxxl {
  @media all and (min-width: get(xxxl)) {
    @content;
  }
}

@mixin between($min, $max) {
  @media all and (min-width: $min) and (max-width: $max) {
    @content;
  }
}
